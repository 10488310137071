<template>
  <div class="about-us-component">
    <div class="auc-title-list-wrap">
      <div class="auc-title-list">
        <!-- <span @click="changeTabActive(0)" class="anchor" :class="{ 'tab-active': tabActive[0] }">
          <i class="icon icon1"></i><br>
          公司简介
        </span> -->
        <span
          @click="changeTabActive(1)"
          class="anchor"
          :class="{ 'tab-active': tabActive[1] }"
        >
          <i class="icon icon2"></i><br />
          资质证照
        </span>
        <span
          @click="changeTabActive(2)"
          class="anchor"
          :class="{ 'tab-active': tabActive[2] }"
        >
          <i class="icon icon3"></i><br />
          联系我们
        </span>
        <span
          @click="changeTabActive(3)"
          class="anchor"
          :class="{ 'tab-active': tabActive[3] }"
        >
          <i class="icon icon4"></i><br />
          公司荣誉
        </span>
        <!-- <span @click="changeTabActive(4)" class="anchor" :class="{ 'tab-active': tabActive[4] }">
          <i class="icon icon4"></i><br>
          外部报道
        </span> -->
        <span
          @click="changeTabActive(5)"
          class="anchor"
          :class="{ 'tab-active': tabActive[5] }"
          style="display: none"
        >
          <i class="icon icon4"></i><br />
          网站公告
        </span>
      </div>
    </div>
    <div class="auc-content-list">
      <ul>
        <!-- <li :class="{ 'tab-active': tabActive[0] }" class="auc-content auc-content1">
          <img src="./img/content1-banner.png" alt="" class="content1-banner">
          <div class="data-list">
            <span class="data-option">
              <i class="icon icon1"></i><br>
              2014.03.14<br>
              <span class="gray">成立时间</span>
            </span>
            <span class="data-option">
              <i class="icon icon2"></i><br>
              {{ info.total_amounts }}<br>
              <span class="gray">累计交易（亿元）</span>
            </span>
            <span class="data-option">
              <i class="icon icon3"></i><br>
              {{ info.total_users }}<br>
              <span class="gray">累计注册（万人）</span>
            </span>
          </div>
          <div class="content1-top-text-box">
            <img src="./img/content1-top-text.png" alt="" class="content1-top-text">
          </div>
          <div class="auc-content1-title" style="display:none">创始团队</div>
          <div class="tab-title-list" style="display:none">
            <span class="title title1" :class="{ 'tab-active': tabActive2[0] }" @click="changeTabActive2(0)"><img src="./img/pepole-sl-gray.png" alt=""><span class="pepole-name">申磊</span></span>
            <span class="title title2" :class="{ 'tab-active': tabActive2[1] }" @click="changeTabActive2(1)"><img src="./img/pepole-yyh-gray.png" alt=""><span class="pepole-name">叶映辉</span></span>
            <span class="title title3" :class="{ 'tab-active': tabActive2[2] }" @click="changeTabActive2(2)"><img src="./img/pepole-lsy-gray.png" alt=""><span class="pepole-name">林水源</span></span>
          </div>
          <div class="tab-content-list" style="display:none">
            <div class="content" :class="{ 'tab-active': tabActive2[0] }" ><img src="./img/jj-sl.png" alt=""></div>
            <div class="content" :class="{ 'tab-active': tabActive2[1] }" ><img src="./img/jj-yyh.png" alt=""></div>
            <div class="content" :class="{ 'tab-active': tabActive2[2] }" ><img src="./img/jj-lsy.png" alt=""></div>
          </div>
          <div class="auc-content1-title">平台资方</div>
          <div class="auc-touzi-list">
            <img src="./img/c-lun.png" alt="" class="banner">
            <div class="title">英达钢构&nbsp;&nbsp;&nbsp;&nbsp;[C轮投资方]</div>
            <img src="./img/touzi-ydgg.png" alt="" class="text">
            <img src="./img/b-lun.png" alt="" class="banner">
            <div class="title">和玉资本&nbsp;&nbsp;&nbsp;&nbsp;[B轮投资方]</div>
            <img src="./img/touzi-hyzb.png" alt="" class="text">
            <img src="./img/a-lun.png" alt="" class="banner">
            <div class="title">林广茂&nbsp;&nbsp;&nbsp;&nbsp;[A/B轮投资人]</div>
            <img src="./img/touzi-lgm.png" alt="" class="text">
          </div>
          <div class="auc-content1-title" style="display:none">发展历程</div>
          <img src="./img/content1-fzlc.png" alt="" class="content1-fzlc">
        </li> -->
        <li
          :class="{ 'tab-active': tabActive[1] }"
          class="auc-content auc-content2"
        >
          <img src="./img/content2-bg.png" alt="" class="content2-bg" />
        </li>
        <li
          :class="{ 'tab-active': tabActive[2] }"
          class="auc-content auc-content3"
        >
          <img src="./img/content3-bg.png" alt="" class="content3-bg" />
        </li>
        <li
          :class="{ 'tab-active': tabActive[3] }"
          class="auc-content auc-content4"
        >
          <img src="./img/content4-bg.png" alt="" class="content4-bg" />
        </li>
        <!-- <li :class="{ 'tab-active': tabActive[4] }" class="auc-content auc-content5">
          <ul class="media-list lcf-clear">
            <li class="media-option lcf-clear" v-for="item in reportList" :key="item.id">
              <router-link :to="linkString(item.id)" class="img-box"><img :src="item.image_url" alt=""></router-link>
              <div class="media-text">
                <router-link :to="linkString(item.id)">
                  <h3 class="title">{{ item.title }}</h3>
                  <div class="source-time">{{ item.from }} | {{ item.addtime }}</div>
                  <div class="content">
                    {{ item.instr }}
                  </div>
                </router-link>
              </div>
            </li>
          </ul>
          <div class="auc-content5-paging-wrap" v-if='paging'>
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="page_size"
              layout="total, prev, pager, next, jumper"
              :total="total_records">
            </el-pagination>
          </div>
        </li> -->
        <!-- <li
          :class="{ 'tab-active': tabActive[5] }"
          class="auc-content auc-content6"
          style="display: none"
        >
          <ul class="media-list lcf-clear" v-if="announcementList">
            <li
              class="option"
              v-for="(item, index) in announcementList"
              :key="index"
            >
              <router-link :to="`/about_us/announcement_detail?id=${item.id}`">
                <div class="title">{{ item.title }}</div>
                <div class="time">{{ item.addtime }}</div>
              </router-link>
            </li>
          </ul>
          <div class="auc-content6-paging-wrap" v-if="paging2">
            <el-pagination
              @current-change="handleCurrentChange2"
              :current-page.sync="currentPage2"
              :page-size="page_size2"
              layout="total, prev, pager, next, jumper"
              :total="total_records2"
            >
            </el-pagination>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { Notify } from 'vant'

export default {
  name: 'AboutUs',
  data() {
    return {
      tabActive: [false, true, false, false, false],
      tabActive2: [true, false, false],
      info: {
        online_time: '',
        total_users: '',
        total_invests: '',
        total_amounts: '',
        total_gains: '',
        project_loans: '',
      },
      // 媒体报道
      reportList: null,
      reportNum: 0,
      // 分页
      currentPage: 1,
      page_size: 10,
      page: 1,
      total_records: 0,
      paging: false,
      // 网站公告
      announcementList: null,
      // 分页
      currentPage2: 1,
      page_size2: 10,
      page2: 1,
      total_records2: 0,
      paging2: false,
    }
  },
  mounted() {
    // 请求
    this.requestData()
    // 请求 媒体报道
    const reqData = {
      code: 'media',
      page_size: this.page_size,
      page: this.page,
    }
    this.requestData2(reqData)
    // 请求 网站公告
    const reqData3 = {
      code: 'notice',
      page_size: this.page_size2,
    }
    this.requestData3(reqData3)
  },
  methods: {
    changeTabActive(key) {
      this.noActive()
      this.$set(this.tabActive, key, true)
    },
    noActive() {
      for (let i = 0; i < this.tabActive.length; i += 1) {
        this.$set(this.tabActive, i, false)
      }
    },
    changeTabActive2(key) {
      this.noActive2()
      this.$set(this.tabActive2, key, true)
    },
    noActive2() {
      for (let i = 0; i < this.tabActive2.length; i += 1) {
        this.$set(this.tabActive2, i, false)
      }
    },
    leave() {
      let messagingIframe = null
      messagingIframe = document.createElement('iframe')
      messagingIframe.style.display = 'none'
      document.documentElement.appendChild(messagingIframe)
      messagingIframe.src = 'app/close/click'
    },
    // 接口 投资开户数据
    requestData() {
      api.general
        .artNewUserGuide()
        .then((response) => {
          const res = response.data
          if (res.code === -1) {
            this.info = res.data
            this.info.total_amounts =
              Math.floor((this.info.total_amounts / 100000000) * 100) / 100
            this.info.total_users =
              Math.floor((this.info.total_users / 10000) * 100) / 100
          } else {
            Notify({
              type: 'warning',
              message: res.msg,
            })
          }
        })
        .catch((error) => {
          Notify({
            type: 'warning',
            message: error.toString(),
          })
        })
    },
    linkString(arg) {
      const id = parseFloat(arg)
      return `/about_us/external_report_detail?id=${id}`
    },
    handleCurrentChange(val) {
      const reqData = {
        code: 'media',
        page_size: this.page_size,
        page: `${val}`,
      }
      this.requestData2(reqData)
    },
    // 接口 媒体报道
    requestData2(reqData) {
      api.others
        .ArtAnnouncementNotice(reqData)
        .then((response) => {
          const res = response.data
          if (res.code === -1) {
            this.reportList = res.data
            this.reportNum = res.data.length
            if (res.data.length) {
              this.total_records = res.total_records
              if (
                this.total_records < this.page_size ||
                this.total_records === this.page_size
              ) {
                this.paging = false
              } else {
                this.paging = true
              }
            } else {
              this.paging = false
            }
          } else {
            Notify({
              type: 'warning',
              message: res.msg,
            })
          }
        })
        .catch((error) => {
          Notify({
            type: 'warning',
            message: error.toString(),
          })
        })
    },
    // 接口 网站公告
    requestData3(arg) {
      api.others
        .ArtAnnouncementNotice(arg)
        .then((response) => {
          const resData = response.data
          if (resData.code === -1) {
            this.announcementList = resData.data
            if (resData.data.length) {
              this.total_records2 = resData.total_records
              if (
                this.total_records2 < this.page_size2 ||
                this.total_records2 === this.page_size2
              ) {
                this.paging2 = false
              } else {
                this.paging2 = true
              }
            } else {
              this.paging2 = false
            }
          } else {
            Notify({
              type: 'warning',
              message: resData.msg,
            })
          }
        })
        .catch((error) => {
          Notify({
            type: 'warning',
            message: error.toString(),
          })
        })
    },
    handleCurrentChange2(val) {
      const reqData = {
        code: 'notice',
        page_size: this.page_size,
        page: `${val}`,
      }
      this.requestData(reqData)
    },
  },
}
</script>
<style scoped>
.about-us-component {
  background: #fff;
}
.auc-title-list-wrap {
  width: 100%;
  overflow-x: auto;
}
.auc-title-list {
  width: 135%;
  padding-left: 2%;
  text-align: left;
}
.auc-title-list .anchor {
  display: inline-block;
  padding: 24px 0 16px 0;
  width: 15%;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  color: #666;
}
.auc-title-list .icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auc-title-list .icon1 {
  background-image: url(./img/gray1.png);
}
.auc-title-list .icon2 {
  background-image: url(./img/gray2.png);
}
.auc-title-list .icon3 {
  background-image: url(./img/gray3.png);
}
.auc-title-list .icon4 {
  background-image: url(./img/gray4.png);
}
.auc-title-list .tab-active .icon1 {
  background-image: url(./img/light1.png);
}
.auc-title-list .tab-active .icon2 {
  background-image: url(./img/light2.png);
}
.auc-title-list .tab-active .icon3 {
  background-image: url(./img/light3.png);
}
.auc-title-list .tab-active .icon4 {
  background-image: url(./img/light4.png);
}
.auc-title-list .anchor.tab-active {
  color: #171717;
}
.auc-content-list .auc-content {
  font-size: 16px;
  line-height: 30px;
  color: #171717;
  display: none;
}
.auc-content-list .auc-content.tab-active {
  /*color: #f03611;*/
  display: block;
}
/* 第一页 */
.auc-content1 .content1-banner {
  width: 100%;
  height: auto;
}
.auc-content1 .content1-top-text-box {
  border-bottom: 16px solid #fbfbfb;
}
.auc-content1 .content1-top-text {
  width: 100%;
  height: auto;
}
.auc-content1 .data-list {
  padding: 16px 0;
  text-align: center;
}
.auc-content1 .data-option {
  width: 32%;
  display: inline-block;
  color: #171717;
  line-height: 1.6em;
  font-size: 20px;
}
.auc-content1 .data-option .icon {
  width: 46px;
  height: 46px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auc-content1 .data-option .gray {
  color: #999;
  font-size: 16px;
}
.auc-content1 .data-option .icon1 {
  background-image: url(./img/content1-group1-img1.png);
}
.auc-content1 .data-option .icon2 {
  background-image: url(./img/content1-group1-img2.png);
}
.auc-content1 .data-option .icon3 {
  background-image: url(./img/content1-group1-img3.png);
}
.auc-content1 .content1-img2 {
  width: 100%;
  height: auto;
}
.auc-content-list .content2-bg,
.auc-content-list .content3-bg,
.auc-content-list .content4-bg {
  width: 100%;
  height: auto;
}
.auc-content1 .content1-top-text-box {
  border-bottom: 16px solid #fbfbfb;
}
.auc-content1 .content1-top-text {
  width: 100%;
  height: auto;
}
.auc-content1 .auc-content1-title {
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  padding: 16px 0 8px 0;
  color: #999;
}
.auc-content1 .tab-title-list {
  width: 90%;
  overflow: hidden;
  margin: 0 auto 0;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  padding: 16px 0 32px 0;
}
.auc-content1 .tab-title-list .title {
  width: 33.33%;
  float: left;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.auc-content1 .tab-title-list .pepole-name {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: -32px;
  line-height: 32px;
  color: #999;
  font-size: 16px;
}
.auc-content1 .tab-title-list .tab-active .pepole-name {
  color: #171717;
}
.auc-content1 .tab-title-list .title1 {
  background-image: url(./img/pepole-sl-gray.png);
}
.auc-content1 .tab-title-list .title2 {
  background-image: url(./img/pepole-yyh-gray.png);
}
.auc-content1 .tab-title-list .title3 {
  background-image: url(./img/pepole-lsy-gray.png);
}
.auc-content1 .tab-title-list .title1.tab-active {
  background-image: url(./img/pepole-sl-light.png);
}
.auc-content1 .tab-title-list .title2.tab-active {
  background-image: url(./img/pepole-yyh-light.png);
}
.auc-content1 .tab-title-list .title3.tab-active {
  background-image: url(./img/pepole-lsy-light.png);
}
.auc-content1 .tab-title-list img {
  width: 100%;
  float: left;
  opacity: 0;
}
.auc-content1 .tab-content-list {
  border-bottom: 16px solid #fbfbfb;
}
.auc-content1 .tab-content-list .content img {
  width: 100%;
}
.auc-content1 .tab-content-list .content {
  display: none;
}
.auc-content1 .tab-content-list .content.tab-active {
  display: block;
}
.auc-content1 .tab-content-list .content.tab-active {
  display: block;
}
.auc-touzi-list {
  text-align: center;
  border-bottom: 16px solid #fbfbfb;
}
.auc-touzi-list .title {
  color: #666;
}
.auc-touzi-list .banner {
  width: 90%;
  margin-bottom: 16px;
}
.auc-touzi-list .text {
  width: 100%;
}
.auc-content1 .content1-fzlc {
  width: 100%;
}
/* 第二页 */
/* 第三页 */
.auc-content3 .content3-bg {
  width: 100%;
}
/* 第五页媒体报道 */
.auc-content5 {
  width: 100%;
}
.auc-content5 .media-list {
  width: 90%;
  margin: 0 auto;
}
.auc-content5 .media-option {
  width: 100%;
  margin-bottom: 16px;
}
.auc-content5 .media-option .img-box {
  width: 30%;
  min-height: 48px;
  float: left;
  border: 1px solid #eee;
  background: #fff;
  margin-top: 8px;
}
.auc-content5 .media-option .img-box img {
  width: 100%;
  height: 100%;
}
.auc-content5 .media-option .media-text {
  width: 65%;
  float: right;
  text-align: justify;
}
.auc-content5 .media-option .media-text .title {
  line-height: 30px;
  color: #171717;
  font-size: 18px;
  font-weight: normal;
}
.auc-content5 .media-option .media-text .source-time {
  width: 100%;
  height: 32px;
  line-height: 32px;
  width: 100%;
  font-size: 14px;
  color: #171717;
}
.auc-content5 .media-option .media-text .content {
  font-size: 14px;
  color: #999;
  line-height: 26px;
  max-height: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* 分页 */
.auc-content5-paging-wrap {
  width: 100%;
  height: 60px;
  padding: 45px 0 10px 0;
  text-align: center;
}
.auc-content5 .el-pagination {
  white-space: normal;
}
/* 第6页媒体报道 */
.auc-content6 {
  width: 100%;
}
.auc-content6 .media-list {
  width: 90%;
  margin: 0 auto;
}
.auc-content6 .option {
  width: 100%;
  margin-bottom: 8px;
}
.auc-content6 .option .title {
  line-height: 24px;
  color: #171717;
  font-size: 16px;
  font-weight: normal;
}
.auc-content6 .option .time {
  width: 100%;
  height: 32px;
  line-height: 32px;
  width: 100%;
  font-size: 14px;
  color: #666;
}
/* 分页2 */
.auc-content6-paging-wrap {
  width: 100%;
  height: 60px;
  padding: 45px 0 10px 0;
  text-align: center;
}
.auc-content6 .el-pagination {
  white-space: normal;
}
</style>
